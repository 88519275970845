import Vue from 'vue'
import Vuex from 'vuex'
import jwtDecode from 'jwt-decode'

import $socket from '../lib/socketIo'

import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('access_token') || null,
    userId: localStorage.getItem('user') || null,
    userStats: localStorage.getItem('user_stats') || null,
    userEmail: localStorage.getItem('user_email') || null,
    logoutMessage: '',
    serverLoadAutomation: [],
    serverLoad: {},
    errors: [],
    appStats: {},
    environment: localStorage.getItem('env') || 'Production',
    appTrackingOnlineUsers: [],
    appTrackingData: { topEshop: [], topRoutes: [], uniqueUsers: [], countActiveUsers7days: 0, countActivePast24Hrs: 0 }
  },
  mutations: {
    retrievedToken (state, { token, userId, userStats, userEmail }) {
      state.token = token
      state.userId = userId
      state.userStats = userStats
      state.userEmail = userEmail
    },
    destroyToken (state) {
      state.token = null
      state.userId = null
      state.userStats = null
      state.userEmail = null
    },
    logoutMessageTokenExpired (state) {
      state.logoutMessage = 'Session has expired, please log back in'
    },
    serverLoadAutomation (state, data) {
      state.serverLoadAutomation = data
    },
    serverLoad (state, data) {
      state.serverLoad = data
    },
    errors (state, data) {
      state.errors = data
    },
    appStats (state, data) {
      state.appStats = data
    },
    appTrackingGetLiveUsers (state, data) {
      state.appTrackingOnlineUsers = data
    },
    appTrackingGetData (state, data) {
      state.appTrackingData = data
    }
  },
  getters: {
    isAdmin: state => {
      const decodedToken = state.token !== null ? jwtDecode(state.token) : false
      if (decodedToken) {
        const role = decodedToken.payload.userStats
        if (role === 3) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isTokenValid: state => {
      const decodedToken = state.token !== null ? jwtDecode(state.token) : false
      if (decodedToken) {
        const expire = decodedToken.exp * 1000
        if (expire > new Date().getTime()) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  actions: {
    requestWebportTokenForHCU ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_HCU_API_SERVER}/api/hcu-ai/webport-control/request-access-token`, { appUUID: process.env.VUE_APP_HCU_APP_UUID }, {
          headers: {
            Authorization: 'Bearer ' + process.env.VUE_APP_HCU_APP_TOKEN
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    syncTemps ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_HCU_API_SERVER}/api/hcu-ai/dashboard-connector/sync-temps-for-building`, { appUUID: process.env.VUE_APP_HCU_APP_UUID }, {
          headers: {
            Authorization: 'Bearer ' + process.env.VUE_APP_HCU_APP_TOKEN
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createNewBuildingInHCU ({ commit }, { newBuilding }) {
      newBuilding.appUUID = process.env.VUE_APP_HCU_APP_UUID
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_HCU_API_SERVER}/api/hcu-ai/house-and-building-management/new-building`, newBuilding, {
          headers: {
            Authorization: 'Bearer ' + process.env.VUE_APP_HCU_APP_TOKEN
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getBuildingHeatingDetails ({ commit }, data) {
      data.appUUID = process.env.VUE_APP_HCU_APP_UUID
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_HCU_API_SERVER}/api/hcu-ai/dashboard-connector/get-heating-details/`, data, {
          headers: {
            Authorization: 'Bearer ' + process.env.VUE_APP_HCU_APP_TOKEN
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getDataForHCU ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_HCU_API_SERVER}/api/hcu-ai/dashboard-connector/get-buildings-data-for-overview/`, { appUUID: process.env.VUE_APP_HCU_APP_UUID }, {
          headers: {
            Authorization: 'Bearer ' + process.env.VUE_APP_HCU_APP_TOKEN
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchGlobalSettings ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_HCU_API_SERVER}/api/hcu-ai/dashboard-connector/get-global-settings/`, { appUUID: process.env.VUE_APP_HCU_APP_UUID }, {
          headers: {
            Authorization: 'Bearer ' + process.env.VUE_APP_HCU_APP_TOKEN
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    resetPrevErrorAndIntegral ({ commit }, { buildingId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_HCU_API_SERVER}/api/hcu-ai/dashboard-connector/reset-hcu-error-and-integral/`, { appUUID: process.env.VUE_APP_HCU_APP_UUID, buildingId }, {
          headers: {
            Authorization: 'Bearer ' + process.env.VUE_APP_HCU_APP_TOKEN
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // START Socket IO ===================================================
    connectSocketIo ({ state, commit }) {
      if (state.token !== null) {
        if (!$socket.connected) {
          console.log('Socket not connected')
          setTimeout(() => {
            $socket.query = { token: state.token }
            $socket.io.opts.query = { token: state.token }
            $socket.open()
          }, 500)
          setTimeout(() => {
            console.log('Socket connected', $socket.connected)
          }, 1500)
        } else {
          console.log('already connected')
        }
      } else {
        console.log('logged out')
      }
    },
    socket_adminupdate ({ state, commit }, message) {
      console.log(message)
      if (!state.appTrackingData.uniqueUsers.includes(message.data.user)) {
        state.appTrackingData.uniqueUsers.push(message.data.user)
      }
      if (message.type === 'AppStateChange') {
        if (message.what === 'UserPlus') {
          const findIndexOfActiveUser = state.appTrackingOnlineUsers.findIndex(user => user.user === message.data.user)
          if (findIndexOfActiveUser === -1) {
            state.appTrackingOnlineUsers.push(message.data)
          }
          const indexOfTrackingData = state.appTrackingData.topRoutes.findIndex(itm => itm.route === message.data.route)
          if (indexOfTrackingData !== -1) {
            state.appTrackingData.topRoutes[indexOfTrackingData].count++
          } else {
            state.appTrackingData.topRoutes.push({ route: message.data.route, count: 1 })
          }
          const mostActiveIndex = state.appTrackingData.mostActiveUsers.findIndex(item => item.userId === message.data.user)
          if (mostActiveIndex !== -1) {
            state.appTrackingData.mostActiveUsers[mostActiveIndex].count++
          } else {
            state.appTrackingData.mostActiveUsers.push({ userId: message.data.user, count: 1 })
          }
          state.appTrackingData.countActivePast24Hrs++
          state.appTrackingData.countActiveUsers7days++
        } else if (message.what === 'UserMinus') {
          for (let index = 0; index < state.appTrackingOnlineUsers.length; index++) {
            if (state.appTrackingOnlineUsers[index].user === message.data.user) {
              state.appTrackingOnlineUsers.splice(index, 1)
            }
          }
        }
      } else if (message.type === 'AppStateChangeRouteUpdate' && !message.data.route.includes('modalEshopItem')) {
        const indexOfTrackingData = state.appTrackingData.topRoutes.findIndex(itm => itm.route === message.data.route)
        if (indexOfTrackingData !== -1) {
          state.appTrackingData.topRoutes[indexOfTrackingData].count++
        } else {
          state.appTrackingData.topRoutes.push({ route: message.data.route, count: 1 })
        }
      } else if (message.type === 'AppStateChangeRouteUpdate' && message.data.route.includes('modalEshopItem')) {
        const itemSKU = message.data.route.slice(1).split('/')[1]
        const indexOfTrackingData = state.appTrackingData.topEshop.findIndex(itm => itm.itemSKU === itemSKU)
        if (indexOfTrackingData !== -1) {
          state.appTrackingData.topEshop[indexOfTrackingData].count++
        } else {
          state.appTrackingData.topEshop.push({ itemSKU, count: 1 })
        }
      }
    },
    appTrackingGetLiveUsers ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/stats/live-tracking-data/`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('appTrackingGetLiveUsers', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    appTrackingGetData ({ commit }, { date }) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/stats/tracking-data/`, { date }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('appTrackingGetData', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // END Socket IO =====================================================
    // START Help Center =================================================
    getHelpCenterContent ({ commit }, { type }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/help-center/get/${type}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createHelpCenterContent ({ commit }, { type, content, title, category }) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/help-center/create/${type}`, { content, title, category }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editHelpCenterContent ({ commit }, { id, type, content, title, category }) {
      return new Promise((resolve, reject) => {
        axios.put(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/help-center/edit/${id}`, { content, title, type, category }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteHelpCenterContent ({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/help-center/delete/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // END Help Center ===================================================
    env ({ commit, state }, val) {
      return new Promise((resolve, reject) => {
        localStorage.removeItem('env')
        localStorage.setItem('env', val)
        state.environment = val
        resolve()
      })
    },
    check2FACode ({ commit }, { type, code, email }) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/public/admin-login/second-step-verification`, { type, code, email })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    login ({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/public/admin-login`, credentials)
          .then((response) => {
            const token = response.data
            const decodedToken = jwtDecode(token)
            const userId = decodedToken.payload.user
            const userStats = decodedToken.payload.userStats
            const userEmail = decodedToken.payload.userEmail
            localStorage.setItem('access_token', token)
            localStorage.setItem('user', userId)
            localStorage.setItem('user_stats', userStats)
            localStorage.setItem('user_email', userEmail)
            localStorage.setItem('valid', decodedToken.exp)
            commit('retrievedToken', {
              token,
              userId,
              userStats,
              userEmail
            })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    LogOut ({ commit }) {
      localStorage.clear()
      commit('destroyToken')
      $socket.disconnect()
    },
    logoutMessageTokenExpired ({ commit }) {
      commit('logoutMessageTokenExpired')
    },
    getServerLoadAutomation ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/server-load/automation`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('serverLoadAutomation', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getServerLoad ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/server-load/load`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('serverLoad', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getErrors ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/errors/`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('errors', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteError ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/errors/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteAllErrors ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/errors/delete-all-errors/`, { dummy: 'dummy' }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getErrorList ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/errors/errorList`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getAppStats ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/stats`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('appStats', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addToErrorList ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/errors/errorList`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getLandlords ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/landlords`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addLandlord ({ commit }, landlordDetails) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/landlords/add-new`, landlordDetails, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getUsers ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/users`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteUser ({ commit }, userId) {
      return new Promise((resolve, reject) => {
        axios.delete(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/users/${userId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getProperty ({ commit }, userId) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/properties/get-properties-and-users/${userId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getPropertyLog ({ commit }, param) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/properties/log/${param.propertyId}/${param.skip}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          },
          params: {
            type: param.type
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getUserDevices ({ commit }, { userId, propertyId }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/devices/${userId}/${propertyId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // Eshop ======================= Eshop ======================= Eshop ======================= Eshop =======================
    getWebShopStatus ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/public/eshop/web-shop-enabled`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateWebShopStatus ({ commit }, { type, value }) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/disable-web-shop`, { value }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    pullEshopData ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/items`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    pullEshopCategoriesAndPictures  ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/get-categories-and-pictures`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addEshopCategory  ({ commit }, { newCategory }) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/add-category`, { newCategory }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeEshopCategory  ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/remove-category/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    uploadShopPictures ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/upload-shop-item-picture`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addEshopItem ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/add-item`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editEshopItemNonPrice ({ commit }, data) {
      const itemId = data.itemId
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/update-item-non-price/${itemId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editEshopItemPrice ({ commit }, data) {
      const itemId = data.itemId
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/update-item-price/${itemId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteEshopItem ({ commit }, { itemId, newItems }) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/delete-item/${itemId}`, newItems, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    pullEshopOrders ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/orders`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    pullEshopDiscountCoupons ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/discount-coupons`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addDiscountCoupon ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/generate-discount-coupon`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    pullShippingMethods ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/shipping-methods`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getBroadcasts ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/notifications/broadcasts`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getUsersToBroadcast ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/notifications/broadcast-to-user-list`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    sendBroadcast ({ commit }, { title, message, image, route, toWhom }) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/notifications/broadcast`, { msgTitle: title, msgBody: message, msgImage: image, msgRoute: route, toWhom }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    pullCrossSaleAndUpSaleData ({ commit }, { type }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/up-sale-cross-sale/${type}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveCrossSaleAndUpSaleData ({ commit }, { type, items }) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/up-sale-cross-sale-save`, { type, items }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editShopOrder ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/edit-shop-order`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getRMADetails ({ commit }, { rmaNumber }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/get-rma-details/${rmaNumber}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    pullEshopQTYs ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/check-stock-qty`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    reorderItemsInEshop ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/reorder-eshop-items`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getBudbeeLabel ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/create-budbee-shipment-label`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    processReturn ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/eshop/process-return`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // Devices ==================== Devices ==================== Devices ==================== Devices ====================
    getAllDevices ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/devices/get-all-devices`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getGwDisconnections ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/devices/gateway-disconnection-log`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getGatewayFailuresLog ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/devices/gateway-add-failures-log`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getAllActiveGateways ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/devices/get-active-gateways`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getAllFirmwares ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/devices/get-all-firmwares`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    uploadFirmwareFile ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/devices/upload-new-firmware-file`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    uploadFirmware ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/devices/upload-new-firmware`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    manualFirmwareUpdate ({ commit }, { propertyId, firmwareVersion, type, version, firmwareType }) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/devices/manual-firmware-update`, { propertyId, firmwareVersion, type, version, firmwareType }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getHeartBeatForGateway ({ commit }, { propertyId }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/devices/heart-beat-connections/${propertyId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getWebportDetails ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/webport/get-details`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    generateWebportToken ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/webport/generate-token`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    refreshWebportToken ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/webport/manual-refresh`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    readWebportTag ({ commit }, { tag }) {
      return new Promise((resolve, reject) => {
        axios.get(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/webport/read-tag/${tag}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    inviteNewAdmin ({ commit }, { password, email }) {
      return new Promise((resolve, reject) => {
        axios.post(`${this.state.environment === 'Staging' ? process.env.VUE_APP_API_SERVER : process.env.VUE_APP_API_SERVER_PRODUCTION}/admin/add-admin/`, { password, email }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
})
