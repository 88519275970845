<template>
  <v-container fluid>
    <v-card rounded="xl" elevation="0">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-col class="blue--text">
              <h3>Server Load Real Time</h3>
            </v-col>
            <v-col>
              <div style="display: inline-block;">
                Free CPU <v-icon>mdi-cpu-64-bit</v-icon> {{ serverLoad.cpu ? Math.round(serverLoad.cpu) : 0 }}%
              </div>
              <div style="display: inline-block;" class="ml-6">
                Free Memory <v-icon>mdi-memory</v-icon>{{ serverLoad.memory ? Math.round(serverLoad.memory.freeMemPercentage) : 0 }} %
              </div>
            </v-col>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            Free CPU : {{ serverLoad.cpu ? serverLoad.cpu : 0 }}%<br>

            Total Memory : {{ serverLoad.memory ? serverLoad.memory.totalMemMb : 0 }}Mb <br>
            Used Memory : {{ serverLoad.memory ? serverLoad.memory.usedMemMb : 0 }}Mb <br>
            Used Memory : {{ serverLoad.memory ? serverLoad.memory.usedMemPercentage : 0 }}% <br>
            Free Memory : {{ serverLoad.memory ? serverLoad.memory.freeMemMb : 0 }}Mb <br>
            Free Memory : {{ serverLoad.memory ? serverLoad.memory.freeMemPercentage : 0 }}%
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-col class="blue--text">
              <h3>Automation Processing Time</h3>
            </v-col>

            <v-col><v-icon>mdi-clock</v-icon> {{ average }}ms</v-col>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-sheet color="rgb(0,0,0,0.8)">
              <apexchart
                v-show="!loading"
                :height="300"
                type="line"
                :options="performance"
                :series="automationLoad"
              />
              <v-row
                v-show="loading"
                align="center"
                justify="center"
                style="height: 300px"
              >
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="white"
                  indeterminate
                />
              </v-row>
            </v-sheet>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-col class="blue--text">
              <h3>Automation Evaluation time</h3>
            </v-col>

            <v-col><v-icon>mdi-clock</v-icon> {{ averageEvaluate }}ms</v-col>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-sheet color="rgb(0,0,0,0.8)">
              <apexchart
                v-show="!loading"
                :height="300"
                type="line"
                :options="performanceEva"
                :series="automationLoadEvaluate"
              />
              <v-row
                v-show="loading"
                align="center"
                justify="center"
                style="height: 300px"
              >
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="white"
                  indeterminate
                />
              </v-row>
            </v-sheet>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts
  },
  data: () => ({
    performance: {
      title: {
        text: 'Average time to process automation',
        align: 'center',
        offsetY: 10,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#ffffff'
        }
      },
      colors: ['#00BFFF'],
      chart: {
        id: 'Average time to process automation',
        type: 'line',
        toolbar: {
          color: 'white',
          offsetY: 10,
          offsetX: -10,
          tools: {
            download: true
          }
        },
        zoom: {
          enabled: false
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        }
      },
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: 0
      },
      xaxis: {
        tickAmount: 70,
        labels: {
          style: {
            colors: 'white',
            fontSize: '12px',
            cssClass: 'apexcharts-xaxis-label'
          }
        }
      },
      yaxis: {
        labels: {
          formatter: (value) => value.toFixed(1) + 'ms',
          style: {
            colors: 'white',
            fontSize: '12px',
            cssClass: 'apexcharts-xaxis-label'
          }
        }
      },
      legend: {
        fontSize: '14px',
        labels: {
          colors: 'white'
        }
      },
      tooltip: {
        theme: 'dark',
        y: {
          formatter: (value) => value
        }
      },
      grid: {
        borderColor: '#616161'
      }
    },
    performanceEva: {
      title: {
        text: 'Average time to evaluate automation',
        align: 'center',
        offsetY: 10,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#ffffff'
        }
      },
      colors: ['#00BFFF'],
      chart: {
        id: 'Average time to evaluate automation',
        type: 'line',
        toolbar: {
          color: 'white',
          offsetY: 10,
          offsetX: -10,
          tools: {
            download: true
          }
        },
        zoom: {
          enabled: false
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        }
      },
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: 0
      },
      xaxis: {
        tickAmount: 70,
        labels: {
          style: {
            colors: 'white',
            fontSize: '12px',
            cssClass: 'apexcharts-xaxis-label'
          }
        }
      },
      yaxis: {
        labels: {
          formatter: (value) => value.toFixed(1) + 'ms',
          style: {
            colors: 'white',
            fontSize: '12px',
            cssClass: 'apexcharts-xaxis-label'
          }
        }
      },
      legend: {
        fontSize: '14px',
        labels: {
          colors: 'white'
        }
      },
      tooltip: {
        theme: 'dark',
        y: {
          formatter: (value) => value
        }
      },
      grid: {
        borderColor: '#616161'
      }
    },
    automationLoad: [],
    average: 0,
    automationLoadEvaluate: [],
    averageEvaluate: 0,
    loading: false
  }),
  computed: {
    serverLoad () {
      return this.$store.state.serverLoad
    }
  },
  // mounted () {
  //   const load = this.$store.state.serverLoadAutomation

  // },
  created () {
    this.loading = true
    this.$store.dispatch('getServerLoadAutomation').then(resp => {
      this.automationLoad.push(resp.data[0])
      this.average = resp.data[0].average ? resp.data[0].average.toFixed(3) : ':) No automation has been processed in past 30 minutes :)  '
      this.automationLoadEvaluate.push(resp.data[1])
      this.averageEvaluate = resp.data[1].average.toFixed(3)
      this.loading = false
    })
    this.$store.dispatch('getServerLoad')
  }
}
</script>
<style scoped>
.v-expansion-panel::before {
  box-shadow: none;
}
</style>
