import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/errors',
    name: 'Errors',
    component: () => import('../views/Errors.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/help-center',
    name: 'HelpCenter',
    component: () => import('../views/HelpCenter.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/global-settings',
    name: 'GlobalSettings',
    component: () => import('../views/GlobalSettings.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/landlords',
    name: 'Landlords',
    component: () => import('../views/Landlords.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/ai-heating',
    name: 'AIHeating',
    component: () => import('../views/AIHeating.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/user-detail/:id',
    name: 'UserDetail',
    component: () => import('../views/UserDetail.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/propertylogcompact/:id',
    name: 'PropertyLogCompact',
    component: () => import('../views/PropertyLogCompact.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/devices',
    name: 'AllDevices',
    component: () => import('../views/AllDevices.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/gateways',
    name: 'AllGateways',
    component: () => import('../views/Gateways.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/eshop',
    name: 'Eshop',
    component: () => import('../views/Eshop.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/eshop-cross-sale',
    name: 'Eshop-cross-sale',
    component: () => import('../views/EshopCrossSale.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/webport',
    name: 'Webport',
    component: () => import('../views/Webport.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/orders',
    name: 'EshopOrders',
    component: () => import('../views/EshopOrders.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/discounts',
    name: 'EshopDiscountCoupons',
    component: () => import('../views/Discounts.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/shipping',
    name: 'EshopShippingMethods',
    component: () => import('../views/ShippingMethods.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/broadcasts',
    name: 'Broadcasts',
    component: () => import('../views/Broadcasts.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/report',
    name: 'PlatformReports',
    component: () => import('../views/Report.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  { path: '*', component: () => import('../views/PageNotFound.vue') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (!store.getters.isAdmin) {
      store.dispatch('LogOut')
      next({
        path: '/login'
      })
    } else {
      next()
    }
  }
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (!store.getters.isTokenValid) {
      store.dispatch('logoutMessageTokenExpired').then(() => {
        store.dispatch('LogOut')
      })
      next({
        path: '/login'
      })
    } else {
      next()
    }
  }
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (store.getters.isTokenValid) {
      next()
    } else {
      store.dispatch('LogOut')
      next({
        path: '/login'
      })
    }
  } else {
    next()
  }
})

export default router
