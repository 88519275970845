<template>
  <v-container fluid>
    <v-layout>
      <v-flex>
        <v-row>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <v-card rounded="xl" elevation="0">
              <v-card-text>
                <v-row>
                  <v-col cols="6" style="border-bottom: 1px solid #E8E8E8;">
                    <v-card-title style="padding-bottom: 0;">
                      <div style="position: relative; top: -25px;">
                        <div>Live Users</div>
                        <div style="position: absolute; width: 150px; top: 25px;" class="text-caption">
                          Viewing APP now
                        </div>
                      </div>
                      <v-spacer />
                      <v-avatar
                        style="position: relative; top: -15px;"
                        size="50"
                        color="green lighten-5"
                      >
                        <v-icon medium color="green">
                          mdi-account-outline
                        </v-icon>
                      </v-avatar>
                    </v-card-title>
                    <v-card-text style="padding-bottom: 0;">
                      <v-row>
                        <v-scroll-x-transition mode="out-in">
                          <v-col
                            :key="appTrackingOnlineUsers.length"
                            class="text-h4"
                          >
                            {{ appTrackingOnlineUsers.length }}
                          </v-col>
                        </v-scroll-x-transition>
                      </v-row>
                      <div class="text-left">
                        <v-icon color="green">
                          mdi-trending-up
                        </v-icon>
                        <span class="green--text font-weight-bold">100%</span>
                        <span class="pl-1 text-uppercase text-caption">vs. previous 7 days</span>
                      </div>
                    </v-card-text>
                  </v-col>
                  <v-col style="border-bottom: 1px solid #E8E8E8; border-left: 1px solid #E8E8E8;" cols="6">
                    <v-card-title style="padding-bottom: 0;">
                      <div style="position: relative; top: -25px;">
                        <div>Active Users</div>
                        <div style="position: absolute; width: 150px; top: 25px;" class="text-caption">
                          At least once in past week
                        </div>
                      </div>
                      <v-spacer />
                      <v-avatar
                        style="position: relative; top: -15px;"
                        size="50"
                        color="orange lighten-5"
                      >
                        <v-icon medium color="orange">
                          mdi-star-circle
                        </v-icon>
                      </v-avatar>
                    </v-card-title>
                    <v-card-text style="padding-bottom: 0;">
                      <v-scroll-x-transition mode="out-in">
                        <div :key="appTrackingData.uniqueUsers.length" class="text-h4">
                          {{ appTrackingData ? appTrackingData.uniqueUsers.length : 0 }}
                        </div>
                      </v-scroll-x-transition>
                      <div>
                        <v-icon color="green">
                          mdi-trending-up
                        </v-icon>
                        <span class="green--text font-weight-bold">100%</span>
                        <span class="pl-1 text-uppercase text-caption">vs. previous week</span>
                      </div>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-card-title style="padding-bottom: 0;">
                      <div style="position: relative; top: -25px;">
                        <div>Top Pages</div>
                        <div style="position: absolute; width: 150px; top: 25px;" class="text-caption">
                          Past 7 days
                        </div>
                      </div>
                      <v-spacer />
                      <v-avatar
                        style="position: relative; top: -15px;"
                        size="50"
                        color="blue lighten-5"
                      >
                        <v-icon medium color="blue">
                          mdi-web
                        </v-icon>
                      </v-avatar>
                    </v-card-title>
                    <v-card-text style="padding-bottom: 10px; margin-top: 0px;">
                      <v-row
                        v-for="(item, i) in lodash.orderBy(appTrackingData.topRoutes, ['count'], ['desc']).slice(0, 4)"
                        :key="i"
                        class="text-caption pl-3 pr-3"
                      >
                        <v-col cols="8" style="padding: 0;">
                          {{ item.route }}
                        </v-col>
                        <v-col
                          style="padding: 0;"
                          cols="4"
                          class="text-right"
                        >
                          {{ item.count }} x
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                  <v-col style="border-left: 1px solid #E8E8E8;" cols="6">
                    <v-card-title style="padding-bottom: 0;">
                      <div style="position: relative; top: -25px;">
                        <div>Top Products</div>
                        <div style="position: absolute; width: 150px; top: 25px;" class="text-caption">
                          Past 7 days
                        </div>
                      </div>
                      <v-spacer />
                      <v-avatar
                        style="position: relative; top: -15px;"
                        size="50"
                        color="red lighten-5"
                      >
                        <v-icon medium color="red">
                          mdi-cart
                        </v-icon>
                      </v-avatar>
                    </v-card-title>
                    <v-card-text style="padding-bottom: 10px; margin-top: 0px;">
                      <v-row
                        v-for="(item, i) in lodash.orderBy(appTrackingData.topEshop, ['count'], ['desc']).slice(0, 4)"
                        :key="i"
                        class="text-caption pl-3 pr-3"
                      >
                        <v-col cols="8" style="padding: 0;">
                          {{ item.itemSKU }}
                        </v-col>
                        <v-col
                          style="padding: 0;"
                          cols="4"
                          class="text-right"
                        >
                          {{ item.count }} x
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <v-card rounded="xl" elevation="0">
              <v-card-text>
                <v-row>
                  <v-col cols="6" style="border-bottom: 1px solid #E8E8E8;">
                    <v-card-title style="padding-bottom: 0;">
                      <div style="position: relative; top: -25px;">
                        <div>App 24h</div>
                        <div style="position: absolute; width: 150px; top: 25px;" class="text-caption">
                          Active sessions
                        </div>
                      </div>
                      <v-spacer />
                      <v-avatar
                        style="position: relative; top: -15px;"
                        size="50"
                        color="purple lighten-5"
                        class="purple--text"
                      >
                        24h
                      </v-avatar>
                    </v-card-title>
                    <v-card-text style="padding-bottom: 0;">
                      <v-row>
                        <v-scroll-x-transition mode="out-in">
                          <v-col :key="appTrackingData.countActivePast24Hrs" class="text-h4 purple--text">
                            {{ appTrackingData.countActivePast24Hrs }}
                          </v-col>
                        </v-scroll-x-transition>
                      </v-row>
                      <div class="text-left">
                        <v-icon color="green">
                          mdi-trending-up
                        </v-icon>
                        <span class="green--text font-weight-bold">100%</span>
                        <span class="pl-1 text-uppercase text-caption">vs. previous 24 hours</span>
                      </div>
                    </v-card-text>
                  </v-col>
                  <v-col style="border-bottom: 1px solid #E8E8E8; border-left: 1px solid #E8E8E8;" cols="6">
                    <v-card-title style="padding-bottom: 0;">
                      <div style="position: relative; top: -25px;">
                        <div>App 7d</div>
                        <div style="position: absolute; width: 150px; top: 25px;" class="text-caption">
                          Active sessions
                        </div>
                      </div>
                      <v-spacer />
                      <v-avatar
                        style="position: relative; top: -15px;"
                        size="50"
                        color="indigo lighten-5"
                        class="indigo--text"
                      >
                        7d
                      </v-avatar>
                    </v-card-title>
                    <v-card-text style="padding-bottom: 0;">
                      <v-scroll-x-transition mode="out-in">
                        <div :key="appTrackingData.countActiveUsers7days" class="text-h4 indigo--text">
                          {{ appTrackingData.countActiveUsers7days.toLocaleString('sv-SV') }}
                        </div>
                      </v-scroll-x-transition>
                      <div>
                        <v-icon color="green">
                          mdi-trending-up
                        </v-icon>
                        <span class="green--text font-weight-bold">100%</span>
                        <span class="pl-1 text-uppercase text-caption">vs. previous 7 days</span>
                      </div>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card-title style="padding-bottom: 0;">
                      <div style="position: relative; top: -25px;">
                        <div>Most active users</div>
                        <div style="position: absolute; width: 150px; top: 25px;" class="text-caption">
                          Active sessions per user
                        </div>
                      </div>
                      <v-spacer />
                      <v-avatar
                        style="position: relative; top: -15px;"
                        size="50"
                        color="pink lighten-5"
                        class="pink--text"
                      >
                        <v-icon medium color="pink">
                          mdi-account-outline
                        </v-icon>
                      </v-avatar>
                    </v-card-title>
                    <v-card-text class="pb-2">
                      <v-row
                        v-for="(item, i) in lodash.orderBy(appTrackingData.mostActiveUsers, ['count'], ['desc']).slice(0, 4)"
                        :key="i"
                        class="text-caption pl-3 pr-3"
                      >
                        <v-col cols="8" style="padding: 0;">
                          {{ item.userId }}
                        </v-col>
                        <v-col
                          style="padding: 0;"
                          cols="4"
                          class="text-right"
                        >
                          {{ item.count }} x
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card-text>
              <!-- {{ appTrackingOnlineUsers }} -->
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash'
export default {
  data: () => ({
    lodash: _
  }),
  computed: {
    appTrackingOnlineUsers () {
      return this.$store.state.appTrackingOnlineUsers
    },
    appTrackingData () {
      console.log(this.$store.state.appTrackingData)
      return this.$store.state.appTrackingData
    }
    // mostActiveUsers () {
    //   const arr = []
    //   this.appTrackingData.uniqueUsers.forEach(i => {
    //     arr.push({
    //       userId: i,
    //       count: this.appTrackingData.allUsers.filter(user => user === i).length
    //     })
    //   })

    //   return arr
    // }
  },
  created () {
    this.$store.dispatch('appTrackingGetLiveUsers')
    this.$store.dispatch('appTrackingGetData', { date: null })
  }
}
</script>
