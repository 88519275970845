<template>
  <v-container fluid>
    <v-layout>
      <v-flex>
        <v-card rounded="xl" elevation="0">
          <v-row>
            <v-col class="fill-height d-flex flex-column justify-center align-center">
              <v-icon color="red">
                mdi-alert-circle
              </v-icon>
              App Errors
              <h2 class="red--text">
                {{ appStats.errors }}
              </h2>
            </v-col>
            <v-col class="fill-height d-flex flex-column justify-center align-center">
              <v-icon color="green">
                mdi-account-group
              </v-icon>
              Registered Users
              <h2 class="green--text">
                {{ appStats.users }}
              </h2>
            </v-col>
            <v-col class="fill-height d-flex flex-column justify-center align-center">
              <v-icon color="orange">
                mdi-account-supervisor
              </v-icon>
              Active Landlords
              <h2 class="orange--text">
                {{ appStats.landlords }}
              </h2>
            </v-col>
            <v-col class="fill-height d-flex flex-column justify-center align-center">
              <v-icon color="blue">
                mdi-power-settings
              </v-icon>
              Active Admins
              <h2 class="blue--text">
                {{ appStats.admins }}
              </h2>
            </v-col>
            <v-col class="fill-height d-flex flex-column justify-center align-center">
              <v-icon color="purple">
                mdi-refresh-auto
              </v-icon>
              Automations count
              <h2 class="purple--text">
                {{ appStats.automations }}
              </h2>
            </v-col>
          </v-row>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
  }),
  computed: {
    appStats () {
      return this.$store.state.appStats
    }
  },
  created () {
    this.$store.dispatch('getAppStats')
  }
}
</script>
