<template>
  <v-container
    id="dashboard-view"
    fluid
  >
    <v-row>
      <v-col>
        <live-stats />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <dashboard-stats />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <automation-load />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AutomationLoad from '../components/AutomationLoad.vue'
import DashboardStats from '../components/DashboardStats.vue'
import LiveStats from '../components/DashboardLiveStats.vue'

export default {
  name: 'Home',

  components: {
    AutomationLoad,
    DashboardStats,
    LiveStats
  },
  data () {
    return {
      selectedView: '1'
    }
  },
  computed: {
    isLoggedIn () {
      return !!this.$store.state.token
    }
  },
  watch: {
  },
  created () {
    if (this.isLoggedIn) {
      this.$store.dispatch('connectSocketIo')
    }
  },
  methods: {
  }
}
</script>
