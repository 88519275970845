
<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-if="isAdmin"
      v-model="drawer"
      dark
      color="#031738"
      app
    >
      <v-list-item style="color: #b4cde8">
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Homevision.se
          </v-list-item-title>
          <v-list-item-subtitle>
            Administration Dashboard
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-select
          v-if="isAdmin"
          v-model="environment"
          :items="items2"
          class="selector"
          style="color: #b4cde8"
          solo
          dense
        />
      </v-list-item>
      <v-divider />
      <v-list
        v-for="item in items"
        :key="item.title"
        dense
      >
        <v-list-item
          v-if="!item.divider"
          :to="item.link"
          nav
          link
          style="color: #b4cde8"
          :style="item.color ? `color: ${item.color}` : ''"
        >
          <v-list-item-icon>
            <v-icon style="color: #b4cde8" :style="item.color ? `color: ${item.color}` : ''">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <span v-else>
          <span class="ml-2 blue--text text--lighten-3">{{ item.title }}</span>
          <v-divider />
        </span>
      </v-list>
      <span style="font-size: 14px; margin-left: 5px; margin-top: 10px;" class="white--text text-caption">v.2.2.0-14224</span>
    </v-navigation-drawer>

    <v-app-bar
      v-if="isAdmin"
      flat
      color="#eff4fa"
      app
      dense
    >
      <v-app-bar-nav-icon v-show="isAdmin" @click="drawer = !drawer" />

      <!-- <span style="font-size: 14px;">v.2.0.0-14922</span> -->
      <v-toolbar-title v-show="isAdmin" class="mr-6 text-overline">
        <div style="text-align: right; font-size: 14px;">
          <span style="color: #757575;">Session expires in: {{ new Date(timerCount * 1000).toISOString().substr(11, 8) }}</span>
        </div>
      </v-toolbar-title>

      <v-spacer />

      <span style="margin-right: 10px; color: #757575;" class="text-capitalize font-weight-bold">{{ userEmail.split('@')[0] }}</span>
      <v-menu
        offset-y
        right
        origin="center center"
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            color="#b5cce8"
            size="35"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon dark>
              mdi-account-circle
            </v-icon>
          </v-avatar>
        </template>

        <v-list>
          <v-list-item>
            <!-- <v-list-item-title>Logout</v-list-item-title> -->

            <v-btn
              v-if="isAdmin"
              color="red"
              plain
              dark
              @click="logout"
            >
              Logout
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main style="background-color: #eff4fa;">
      <v-slide-x-transition mode="out-in">
        <router-view v-if="!hide" />
      </v-slide-x-transition>
    </v-main>
    <v-dialog
      v-model="loading"
      persistent
      width="500"
    >
      <v-card
        color="dark"
        dark
      >
        <v-card-text class="text-h6 pt-5">
          Please stand by...changing environment...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-5"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'App',

  data: () => ({
    drawer: null,
    items: [
      { title: 'Dashboard', icon: 'mdi-view-dashboard', link: '/' },
      { title: 'Errors', icon: 'mdi-alert-circle-outline', link: '/errors' },
      { title: 'Global Settings', icon: 'mdi-cogs', link: '/global-settings' },
      { title: 'AI Heating', icon: 'mdi-radiator', link: '/ai-heating', color: '#ff847c' },
      { divider: true, title: 'App Management' },
      { title: 'Help Center', icon: 'mdi-account-question-outline', link: '/help-center', color: '#93e4c1' },
      { title: 'Landlords', icon: 'mdi-account-tie', link: '/landlords', color: '#93e4c1' },
      { title: 'Users', icon: 'mdi-account-multiple', link: '/users', link2: '/user-detail/', color: '#93e4c1' },
      { title: 'Devices', icon: 'mdi-sitemap', link: '/devices', color: '#93e4c1' },
      { title: 'Gateways', icon: 'mdi-monitor-cellphone-star', link: '/gateways', color: '#93e4c1' },
      { title: 'Webport', icon: 'mdi-database-arrow-up', link: '/webport', color: '#93e4c1' },
      { title: 'Broadcasts', icon: 'mdi-bullhorn', link: '/broadcasts', color: '#93e4c1' },
      { divider: true, title: 'E-Shop' },
      { title: 'Eshop Items', icon: 'mdi-basket', link: '/eshop' },
      { title: 'Eshop CrossSale', icon: 'mdi-crosshairs-question', link: '/eshop-cross-sale' },
      { title: 'Orders', icon: 'mdi-store', link: '/orders' },
      { title: 'Discounts Generator', icon: 'mdi-sale', link: '/discounts' },
      { title: 'Shipping Methods', icon: 'mdi-truck', link: '/shipping' },
      { divider: true, title: 'Repors and Support' },
      { title: 'Reports', icon: 'mdi-chart-areaspline', link: '/report' },
      { title: 'Help Desk Support Material', icon: 'mdi-help-box' }
    ],
    items2: ['Production', 'Staging'],
    right: null,
    loading: false,
    hide: false,
    timerCount: 10
  }),
  computed: {
    isAdmin () {
      return this.$store.getters.isAdmin
    },
    userEmail () {
      return this.$store.state.userEmail
    },
    environment: {
      get () {
        return this.$store.state.environment
      },
      set (val) {
        this.loading = true
        this.hide = true
        this.$store.dispatch('env', val).then(() => {
          setTimeout(() => {
            this.hide = false
            this.$forceUpdate()
          }, 500)
          setTimeout(() => {
            this.loading = false
          }, 3000)
        })
      }
    },
    ...mapGetters({ token: 'isTokenValid' })
  },
  watch: {
    timerCount: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            const timeNow = Math.round(Date.now() / 1000)
            const valid = Number(localStorage.getItem('valid'))
            const timeout = valid - timeNow
            this.timerCount = timeout
          }, 1000)
        } else {
          if (this.isAdmin) {
            this.$store.dispatch('logoutMessageTokenExpired').then(() => {
              this.logout()
            })
          }
        }
      },
      immediate: true
    },
    isAdmin: {
      handler (value) {
        if (value) {
          this.timerCount = 3600
        }
      },
      immediate: true
    }
  },
  created () {
    document.title = 'Dashboard-Homevision'
  },
  methods: {
    logout () {
      this.$store.dispatch('LogOut').then(response => {
        this.$router.replace('/login')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.selector {
  max-width: 200px;
}
.background {
  background: #FCF8F3;
  // color: #7AA5D6;
}

</style>
