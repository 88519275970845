import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './assets/style/main.css'
import wysiwyg from 'vue-wysiwyg'
import 'vue-wysiwyg/dist/vueWysiwyg.css'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import axios from 'axios'
import JsonExcel from 'vue-json-excel'

import VueSocketIOExt from 'vue-socket.io-extended'
import $socket from './lib/socketIo'

import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'

import 'leaflet/dist/leaflet.css'

Vue.config.productionTip = false

Vue.component('downloadExcel', JsonExcel)

Vue.use(wysiwyg, { maxHeight: '800px' })
Vue.use(CKEditor)
Vue.use(Chartkick.use(Chart))
Vue.use(VueSocketIOExt, $socket, { store })
new Vue({
  router,
  store,
  vuetify,
  mounted () {
    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      if (error.response.status === 401 && store.state.token) {
        store.dispatch('logoutMessageTokenExpired').then(() => {
          store.dispatch('LogOut')
          router.push('/login')
        })
      }
      return Promise.reject(error)
    })
  },
  render: h => h(App)
}).$mount('#app')
